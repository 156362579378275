import {
  Box,
  Card,
  CardHeader,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import useSettings from "../../../../hooks/useSettings";
import SiteInfoMap from "./SiteInfoMap";
import _ from "lodash";
import ShowWhen from "../../../common/ShowWhen";
import ImagesWrapper from "../../../common/ImagesWrapper";
import { useEffect, useState } from "react";
import { setSiteSurveyAndDevicePhotos } from "../../../../utils/helper";

const SiteInfoSection = function (props) {
  const { settings } = useSettings();
  const schemeData = props.schemeData;
  const coord = _.get(schemeData, "survey_data.location.coords", null);
  let location = null;
  if (coord) {
    location = {
      lng: coord.longitude,
      lat: coord.latitude,
    };
  }

  const [surveyImages, setSurveyImages] = useState([]);

  useEffect(() => {
    const { siteSurveyImages } = setSiteSurveyAndDevicePhotos(props.schemeData);
    setSurveyImages(siteSurveyImages);
  }, [props.schemeData]);

  return (
    <Grid container spacing={3}>
      <Grid
        item
        lg={settings.compact ? 6 : 4}
        md={6}
        xl={settings.compact ? 6 : 3}
        xs={12}
      >
        <Card>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography color="textPrimary" variant="subtitle2">
                    Site Name
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {schemeData["blockName"]}-{schemeData["panchayatName"]}-
                    {schemeData["subScheme"]}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography color="textPrimary" variant="subtitle2">
                    District
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {schemeData["districtName"]}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography color="textPrimary" variant="subtitle2">
                    Block
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {schemeData["blockName"]}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography color="textPrimary" variant="subtitle2">
                    Panchayat
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {schemeData["panchayatName"]}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography color="textPrimary" variant="subtitle2">
                    Ward
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    Ward - {schemeData["name"]}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography color="textPrimary" variant="subtitle2">
                    Scheme/Sub-Scheme
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {schemeData["subScheme"]}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography color="textPrimary" variant="subtitle2">
                    Scheme Code
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {schemeData["subScheme"]}-{schemeData["schemeId"]}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Card>
      </Grid>
      <Grid
        item
        lg={settings.compact ? 6 : 4}
        md={6}
        xl={settings.compact ? 6 : 3}
        xs={12}
      >
        <Card>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography color="textPrimary" variant="subtitle2">
                    Device ID
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    <ShowWhen
                      when={schemeData?.regData?.barcode}
                      component={schemeData?.regData?.barcode?.padStart(5, "0")}
                      otherwise={schemeData?.other?.deviceid?.padStart(5, "0")}
                    />
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography color="textPrimary" variant="subtitle2">
                    Anurakshak
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    <ShowWhen
                      when={schemeData?.survey_data?.anurakshak}
                      component={schemeData?.survey_data?.anurakshak?.name}
                      otherwise={schemeData?.other?.op_name?.padStart(5, "0")}
                    />
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography color="textPrimary" variant="subtitle2">
                    Anurakshak Contact
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    <ShowWhen
                      when={schemeData?.survey_data?.anurakshak}
                      component={
                        schemeData?.survey_data?.anurakshak?.mobile_number
                      }
                      otherwise={schemeData?.other?.op_number}
                    />
                  </Typography>
                </TableCell>
              </TableRow>
              
              <TableRow>
                <TableCell>
                  <Typography color="textPrimary" variant="subtitle2">
                    Targeted Household
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {schemeData["household"]}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography color="textPrimary" variant="subtitle2">
                    FHTC
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {schemeData["fhtc"]}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography color="textPrimary" variant="subtitle2">
                    Motor HP
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {schemeData["motorCapacity"]}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Card>
      </Grid>
      <Grid
        item
        lg={settings.compact ? 6 : 4}
        md={6}
        xl={settings.compact ? 6 : 3}
        xs={12}
      >
        <SiteInfoMap locationinfo={location} />
      </Grid>
      <Grid
        item
        lg={settings.compact ? 6 : 4}
        md={6}
        xl={settings.compact ? 6 : 3}
        xs={12}
      >
        {/* <SiteInfoImages smsid={smsid} schemeData={schemeData} /> */}
        <Card sx={{ height: "100%" }}>
          <ShowWhen
            when={surveyImages?.length > 0}
            component={
              <Box sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                <ImagesWrapper
                  images={surveyImages}
                  title={"Scheme Images"}
                  titleStyle={{
                    fontSize: "1.0938rem",
                    color: "#172b4d!important",
                    mb: 1.1,
                  }}
                />
              </Box>
            }
            otherwise={
              <>
                <CardHeader title="Scheme Images" />
                <Typography p={2}>No Scheme Image Available!</Typography>
              </>
            }
          />
        </Card>
      </Grid>
    </Grid>
  );
};

export default SiteInfoSection;
