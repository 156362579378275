import toast from "react-hot-toast";
import { API } from "../config";
import { get, post } from "../slices/service";

export const getArchivedWard = async ({ wardId = null }) => {
    try {
        if (!wardId) {
            throw new Error("Ward ID is missing!");
        }
        const archivedWardRes = await get(API.base_url + "phed/archived-ward", { wardId });
        if (archivedWardRes.statusCode === 200) {
            return archivedWardRes;
        } else if (archivedWardRes.statusCode === 404) {
            return null;
        } else {
            throw new Error(
                archivedWardRes.message || "Error occurred while getting the archived ward!"
            );
        }
    } catch (error) {
        toast.error(
            error.message || "Error occurred while getting the archived ward!",
            {
                duration: 4000,
            }
        );
    }
};


export const updateComparisonWard = async ({ payload, wardData = null }) => {
    try {
        if (!wardData?.id) {
            throw new Error("Ward ID is required!")
        }
        payload.wardData = wardData
        const updateRes = await post(API.base_url + "phed/update-comparison-ward", payload);
        if (updateRes.statusCode === 200) {
            toast.success(updateRes?.message || "Successfully updated!", {
                duration: 4000,
            });
            return updateRes;
        } else if (updateRes.statusCode === 400) {
            toast.error(updateRes?.message || "Missing some fields!", {
                duration: 4000,
            });
            return null;
        } else {
            throw new Error(updateRes.message || "Error while updating ward details!");
        }
    } catch (error) {
        toast.error(
            error.message || "Error occurred while updating the ward details!",
            {
                duration: 4000,
            }
        );
    }
};

export const ExportWardsSchemeReports = async ({ district = '' }) => {
    try {

        const reportRes = await get(API.base_url + `phed/debugging/wards-reports`, { district });
        if (reportRes.statusCode === 201 || reportRes.statusCode === 200) {
            toast.success("Successfully exported!", {
                duration: 4000,
            });
            return reportRes;
        } else if (reportRes.statusCode === 400) {
            toast.error(reportRes?.message || "Error in exporting!", {
                duration: 4000,
            });
            return;
        } else {
            throw new Error(
                reportRes.message || "Error in exporting!"
            );
        }
    } catch (error) {
        toast.error(error.message || "Error occurred in exporting!", { duration: 4000, });
    }
};
