import React, { useEffect, useState } from "react";
import {
    Typography,
    ListItemButton,
    Grid,
    Box,
    Divider,
} from "@mui/material";
import AvarageList from "./AvarageList";
import CustomAccordion from "../../../common/CustomAccordian";
import { formatDate, formatDecimal } from "../../../../utils/helper";
import { get } from "../../../../slices/service";
import { API } from "../../../../config";
import { Loader } from "../../../Loader/Loader";

const YearlyRecord = ({ smsid = null }) => {
    const [expandedYear, setExpandedYear] = useState(false);
    const [expandedMonth, setExpandedMonth] = useState("");
    const [selectedMonthData, setSelectedMonthData] = useState(null);
    const [yearAvgData, setYearAvgData] = useState({});
    const [loading, setLoading] = useState(false);

    const handleOpenAccordion = (yearKey) => (event, isExpanded) => {
        setExpandedYear(isExpanded ? yearKey : false);
        if (isExpanded && yearAvgData.yearlyAverages[yearKey]) {
            const firstMonth = Object.keys(yearAvgData.yearlyAverages[yearKey].monthlyAvg[0])[0];
            const firstMonthData = yearAvgData.yearlyAverages[yearKey].monthlyAvg[0][firstMonth];
            setExpandedMonth(firstMonth);
            setSelectedMonthData(firstMonthData);
        }
    };

    const handleMonthClick = (month, monthData) => {
        setExpandedMonth(month);
        setSelectedMonthData(monthData);
    };


    useEffect(() => {
        setYearAvgData({})
        async function fetchData(id) {
            const smsid = id
            try {
                setLoading(true)
                const response = await get(API.base_url + "phed/ward-yearly-avarage", { smsid })
                if (response?.data?.length > 0) {
                    setYearAvgData(response.data[0])
                }
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false)
            }
        }
        if (smsid) {
            fetchData(smsid);
        }
    }, [smsid])


    // Add check to ensure yearlyAverages exists
    if (!yearAvgData?.yearlyAverages || Object.keys(yearAvgData.yearlyAverages).length === 0) {
        return <></>;
    }

    if (loading) {
        return (
            <Loader />
        )
    }

    return (
        <>
            {Object.keys(yearAvgData.yearlyAverages).map((yearKey) => {
                const yearData = yearAvgData.yearlyAverages[yearKey];
                const months = yearData.monthlyAvg.map((mavg) => {
                    const monthName = Object.keys(mavg)[0];
                    const monthData = mavg[monthName];
                    return { monthName, monthData };
                });

                return (
                    <CustomAccordion
                        expandedItem={yearKey}
                        expanded={expandedYear}
                        id="panel1bh-header"
                        onChange={handleOpenAccordion(yearKey)}
                        title={
                            <>
                                <span>{yearKey} Average List ➙</span>
                                <span style={{ fontWeight: "normal", color: "#999", gap: "10px", display: "flex" }}>
                                    Electricity (Hrs): <span style={{ marginLeft: 5, color: "blue" }}>{formatDecimal(yearData.averageElectricity)}</span>
                                    Motor Running (Hrs): <span style={{ color: "blue" }}>{formatDecimal(yearData.averageMotor)}</span>
                                    Water Volume (KL): <span style={{ color: "blue" }}>{formatDecimal(yearData.averageWater)}</span>
                                </span>
                            </>
                        }
                    >
                        <Grid container component="main">
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={1.5}
                                sx={{
                                    mb: { xs: 5, sm: 5 },
                                }}
                            >
                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    {months.map(({ monthName, monthData }, idx) => (
                                        <React.Fragment key={idx}>
                                            {idx === 0 && (
                                                <Divider sx={{ borderBottomWidth: 1, width: '100%', my: 0.2 }} />
                                            )}
                                            <ListItemButton
                                                sx={{ cursor: 'pointer', mt: 0 }}
                                                onClick={() => handleMonthClick(monthName, monthData)}
                                                selected={expandedMonth === monthName}
                                            >
                                                <Typography sx={{ textTransform: "capitalize" }}>{monthName}</Typography>
                                            </ListItemButton>
                                            <Divider sx={{ borderBottomWidth: 1, width: '100%', my: 0.2 }} />
                                        </React.Fragment>
                                    ))}
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={10}>
                                {selectedMonthData && (
                                    <AvarageList
                                        data={selectedMonthData.weeklyDetail.map((week, idx) => {
                                            return {
                                                ...week,
                                                duration: `${formatDate(week.weekStart, "date")} to ${formatDate(week.weekEnd, "date")}`,
                                                electricity: formatDecimal(week.averageElectricity),
                                                motor: formatDecimal(week.averageMotor),
                                                water: formatDecimal(week.averageWater),
                                            };
                                        })}
                                        title={
                                            <>
                                                <span style={{ fontWeight: 500, textTransform: "capitalize" }}>{expandedMonth} Average List ➙</span>
                                                <span style={{ fontWeight: "normal", color: "#999", gap: "10px", display: "flex" }}>
                                                    Electricity (Hrs): <span style={{ marginLeft: 5, color: "blue" }}>{formatDecimal(selectedMonthData.averageElectricity)}</span>
                                                    Motor Running (Hrs): <span style={{ color: "blue" }}>{formatDecimal(selectedMonthData.averageMotor)}</span>
                                                    Water Volume (KL): <span style={{ color: "blue" }}>{formatDecimal(selectedMonthData.averageWater)}</span>
                                                </span>
                                            </>
                                        }
                                        fileName={"Motor_List"}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </CustomAccordion>
                );
            })}
        </>
    );
};

export default YearlyRecord;
