import { Box, Container, Grid, } from "@material-ui/core";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { LocationFilter } from "../../../components/dashboard/shemes";
import useSettings from "../../../hooks/useSettings";
import { useState } from "react";
import PageHeading from "../../../components/PageHeading";
import { getSchemes, resetSchemes, setFilters } from "../../../slices/siteInfo";
import { resetOpData } from "../../../slices/schemesDeviceOperational";
import AssignedList from "./installation-report/assignedList";
import { useEffect } from "react";
import { convertToFilterDate } from "../../../utils/helper";
import NotAssignedList from "./installation-report/notAssignedList";
import ShowWhen from "../../../components/common/ShowWhen";
import { Loader } from "../../../components/Loader/Loader";
import CustomAccordion from "../../../components/common/CustomAccordian";
import { resetInstallationState } from "../../../slices/installation";

function InstallationReport() {
  const { settings } = useSettings();
  const { schemes, lastSearchFilter } = useSelector(
    (state) => state.siteinfo
  );
  const [allSchemes, setAllSchemes] = useState([]);
  const [assignedSchemes, setAssignedSchemes] = useState([]);
  const [notAssignedSchemes, setNotAssignedSchemes] = useState([]);
  const [completedSchemes, setCompletedSchemes] = useState([]);
  const [pendingSchemes, setPendingSchemes] = useState([]);
  const [installPending, setInstallPending] = useState([]);
  const [surveyPendingState, setSurveyPendingState] = useState([]);
  const [verifPending, setVerifPending] = useState([]);
  const [rejectedSchemes, setRejectedSchemes] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [from_Ts, setFrom_Ts] = useState(null);
  const [to_Ts, setTo_Ts] = useState(null);
  const [expanded, setExpanded] = useState(false);

  const dispatch = useDispatch();
  const [schemeLocation, setSchemeLocation] = useState({});
  const onChangeLocation = (data) => {
    const keys = Object.keys(data);
    let allReset = true;
    for (let i = 0; i < keys.length; i++) {
      allReset = allReset && !data[keys[i]];
    }

    if (allReset) {
      setAllSchemes([]);
      dispatch(resetSchemes());
      dispatch(resetOpData());
    }
    setSchemeLocation(data);
  };

  const sendGetSchemeReq = () => {
    let d = {};
    if (schemeLocation.district) {
      d["district"] = schemeLocation.district;
    }
    if (schemeLocation.division) {
      d["division"] = schemeLocation.division;
    }
    if (schemeLocation.block) {
      d["block"] = schemeLocation.block;
    }
    if (schemeLocation.panchayat) {
      d["panchayat"] = schemeLocation.panchayat;
    }
    if (schemeLocation.ward) {
      d["ward"] = schemeLocation.ward;
    }
    dispatch(setFilters(schemeLocation));
    if (from_Ts == null && to_Ts == null) {
      dispatch(getSchemes(d));
    } else if ((schemeLocation?.district === null || schemeLocation?.district === undefined || schemeLocation?.district === '') && from_Ts !== null && to_Ts !== null) {
      dispatch(getSchemes(null, [from_Ts, to_Ts]));
    } else {
      dispatch(getSchemes(d, [from_Ts, to_Ts]));
    }
  };

  useEffect(() => {
    dispatch(resetInstallationState());
  }, [dispatch])

  useEffect(() => {
    if (
      schemeLocation.district ||
      schemeLocation.block ||
      schemeLocation.panchayat ||
      schemeLocation.ward || to_Ts
    ) {
      sendGetSchemeReq();
    }
  }, [schemeLocation, to_Ts]);

  useEffect(() => {
    setAllSchemes(schemes["data"]);
    const schemesList = schemes["data"];
    const allAssignedSchemes = schemesList?.filter(
      (e) => e.installation_user_mapping
    );
    setAssignedSchemes(allAssignedSchemes);

    const yetToAssignSchemes = schemesList?.filter(
      (e) => !e.installation_user_mapping
    );
    setNotAssignedSchemes(yetToAssignSchemes);

    const completed = allAssignedSchemes?.filter(
      (e) => e.verification && e.verification.status === "Approved"
    );
    setCompletedSchemes(completed);

    const siteSurveyPending = allAssignedSchemes?.filter((e) => !e.survey_data);
    const installationPending = allAssignedSchemes?.filter(
      (e) => e.survey_data && !e.installation_data
    );
    const verificationPending = allAssignedSchemes?.filter(
      (e) => e.survey_data && e.installation_data && !e.verification
    );
    const rejectedSchemes = allAssignedSchemes?.filter(
      (e) => e.installation_data && e.installation_data.rejected_by
    );
    setSurveyPendingState(siteSurveyPending);
    setInstallPending(installationPending);
    setVerifPending(verificationPending);
    setRejectedSchemes(rejectedSchemes);
    setPendingSchemes([
      ...siteSurveyPending,
      ...installationPending,
      ...verificationPending,
    ]);
  }, [schemes]);


  useEffect(() => {
    if (dateRange[0] !== null && dateRange[1] !== null) {
      const from = convertToFilterDate(dateRange[0])
      const to = convertToFilterDate(dateRange[1], false, true)
      setFrom_Ts(from)
      setTo_Ts(to)
    } else if (dateRange[0] === null && dateRange[1] === null) {
      setFrom_Ts(null)
      setTo_Ts(null)
    }
  }, [dateRange])


  const handleOpenAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <Helmet>
        <title>Dashboard | Installation Report</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 2,
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <PageHeading
            title={"Installation Report"}
            prevPath={[{ name: "Installation Report" }]}
          />
          <Box sx={{ py: 2, pt: 4 }}>
            <LocationFilter
              onChange={onChangeLocation}
              initialValues={lastSearchFilter}
              schemes={allSchemes}
              downloadExcel={true}
            />
          </Box>
          <Grid container spacing={3}>
            <Grid item xl={12} md={12} xs={12}>
              <div
                style={{
                  display: "flex",
                  flexDirectio: "row",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ fontWeight: "bold", color: "#999" }}>
                  Total :
                  <span style={{ marginLeft: 5, color: "blue" }}>
                    {allSchemes.length}
                  </span>
                </div>

                <div style={{ fontWeight: "bold", color: "#999" }}>
                  Yet to Assign :
                  <span style={{ marginLeft: 5, color: "blue" }}>
                    {notAssignedSchemes.length}
                  </span>
                </div>

                <div style={{ fontWeight: "bold", color: "#999" }}>
                  Assigned :
                  <span style={{ marginLeft: 5, color: "blue" }}>
                    {assignedSchemes.length}
                  </span>
                </div>

                <div style={{ fontWeight: "bold", color: "#999" }}>
                  Completed :
                  <span style={{ marginLeft: 5, color: "green" }}>
                    {completedSchemes.length}
                  </span>
                </div>

                <div style={{ fontWeight: "bold", color: "#999" }}>
                  Rejected :
                  <span style={{ marginLeft: 5, color: "red" }}>
                    {rejectedSchemes.length}
                  </span>
                </div>

                <div style={{ fontWeight: "bold", color: "#999" }}>
                  Pending :
                  <span style={{ marginLeft: 5, color: "red" }}>
                    {pendingSchemes.length}
                  </span>
                  <span style={{ marginLeft: 5 }}>
                    [Site Survey Pending {surveyPendingState.length}, Installation Pending:{" "}
                    {installPending.length}, Approval Pending: {verifPending.length}
                    ]
                  </span>
                </div>
              </div>
            </Grid>
            <Grid item xl={12} md={12} xs={12}>
              <CustomAccordion expandedItem={"panel0"} expanded={expanded} id={'panel1bh-header'} onChange={handleOpenAccordion("panel0")} title={`Assigned List: (${assignedSchemes?.length})`} >
                <AssignedList
                  title={"Assigned a TE:"}
                  sx={{ height: "100%" }}
                  schemes={assignedSchemes}
                  fileName={(schemeLocation?.district === null || schemeLocation?.district === undefined || schemeLocation?.district === "") ? "All_District" : assignedSchemes[0]?.districtName}
                  distName={''}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  isDateRange={true}
                  loading={schemes?.status === "loading"}
                />
              </CustomAccordion>
              <CustomAccordion expandedItem={'panel1'} expanded={expanded} id={'panel1bh-header'} onChange={handleOpenAccordion("panel1")} title={`Yet to assign TE: (${notAssignedSchemes?.length})`} >
                <NotAssignedList
                  // title={"Yet to assign TE:"}
                  sx={{ height: "100%" }}
                  schemes={notAssignedSchemes}
                  fileName={notAssignedSchemes[0]?.districtName}
                  locationName={"yet_to_assign_list"}
                />
              </CustomAccordion>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <ShowWhen when={schemes?.status === "loading"} component={<Loader />} />
    </>
  );
}

export default InstallationReport;
