import { useEffect, useState } from "react"
import { ExportWardsSchemeReports } from "../actions/ward"
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { Button, Grid } from "@material-ui/core";
import ExcelJS from "exceljs";
import FileSaver from "file-saver";
import { LOGO_Base64 } from "../constants";
import { getDeptHeading, getDeptName } from "../utils/helper";
import { useSelector } from "react-redux";


const WardsSchemeHealthExport = ({ district = '', disabled, districtName, loading, setLoading, setLoaderMsg }) => {
    const [data, setData] = useState([])
    const [isSingleDistrict, setIsSingleDistrict] = useState(true)
    const deptHeading = getDeptHeading(getDeptName())
    const { district: accessDistricts, } = useSelector((state) => state.siteinfo);

    const handleExport = async ({ district, singleDistrict = true }) => {
        try {
            setLoading(true)
            setLoaderMsg("Loading...")
            setIsSingleDistrict(singleDistrict)
            const exportRes = await ExportWardsSchemeReports({ district })
            if (exportRes?.data?.length > 0) {
                setData(exportRes?.data)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const generateExcel = async (data, filename) => {
        if (!data.length) return;

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Report');

        // Logo as base64 URL  
        const imageId = workbook.addImage({
            base64: LOGO_Base64,
            extension: 'png',
        });

        // Merge cells for the title and logo 
        worksheet.mergeCells('A1:K4');
        worksheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };

        // Add image to merged cell 
        worksheet.addImage(imageId, {
            tl: { col: 4, row: 0.5 },
            ext: { width: 500, height: 55 }, // Adjust dimensions 
        });

        // Add headers after the image
        worksheet.mergeCells('A5:K5');
        worksheet.getCell('A5').value = "Jal Monitoring System";
        worksheet.getCell('A5').font = { bold: true, size: 14 };
        worksheet.getCell('A5').alignment = { horizontal: 'center', vertical: 'middle' };

        worksheet.mergeCells('A6:K6');
        worksheet.getCell('A6').value = `${isSingleDistrict ? districtName?.toUpperCase() : ''} ${deptHeading}`;
        worksheet.getCell('A6').font = { bold: true, size: 14 };
        worksheet.getCell('A6').alignment = { horizontal: 'center', vertical: 'middle' };

        worksheet.mergeCells('A7:K7');
        worksheet.getCell('A7').value = "Overall Functionality Report";
        worksheet.getCell('A7').font = { bold: true, size: 14 };
        worksheet.getCell('A7').alignment = { horizontal: 'center', vertical: 'middle' };

        // Set column headers and data
        const headers = [
            "SLNO", "Division", "Block", "Panchayat", "Ward",
            "Scheme Name", "Functionality Yes/No",
            "Average Motor Running (in Hrs)",
            "Average Water Discharge (In KL)",
            "Electricity Availability (In Hrs)", "Remarks"
        ];

        worksheet.addRow(headers);
        worksheet.getRow(8).font = { bold: true }; // Make headers bold
        // worksheet.getRow(8).alignment = { horizontal: 'center' }; // Center-align headers

        // data rows
        data.forEach((item, index) => {
            worksheet.addRow([
                String(index + 1),
                item?.division || '',
                item?.blockName || '',
                item?.panchayatName || '',
                item?.ward || '',
                item?.schemeId || '',
                item?.functionality ? "YES" : "NO",
                Number(item?.schemeHealth?.motorRunningTime || 0),
                Number(item?.schemeHealth?.waterConsumption ? (item.schemeHealth.waterConsumption / 1000).toFixed(2) : 0),
                Number(item?.schemeHealth?.electricityAvalability || 0),
                ""
            ]);
        });

        // Set column widths 
        worksheet.columns = headers.map(() => ({ width: 18 }));

        // Generate Excel file
        const buffer = await workbook.xlsx.writeBuffer();
        FileSaver.saveAs(new Blob([buffer]), `${filename}.xlsx`);
    };

    useEffect(() => {
        const fileName = isSingleDistrict
            ? `${districtName.toUpperCase()}_OVERALL_REPORTS_${new Date().toISOString().slice(0, 10)}`
            : `ALL_OVERALL_REPORTS_${new Date().toISOString().slice(0, 10)}`
        generateExcel(data, fileName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <Button
                        onClick={() => handleExport({ district: [district] })}
                        color="primary"
                        size="large"
                        sx={{ mr: 3 }}
                        variant="contained"
                        disabled={!disabled || loading}
                    >
                        <CloudDownloadIcon style={{ marginRight: 10 }} />
                        Download Overall Report
                    </Button >
                </Grid>
                <Grid item xs={12} md={4}>
                    <Button
                        onClick={() => handleExport({ district: accessDistricts?.data?.map((d) => d.id), singleDistrict: false })}
                        color="primary"
                        size="large"
                        sx={{ mr: 3 }}
                        variant="contained"
                        disabled={loading}
                    >
                        <CloudDownloadIcon style={{ marginRight: 10 }} />
                        Download All Overall Report
                    </Button >
                </Grid>
            </Grid>
        </>
    )
}

export default WardsSchemeHealthExport