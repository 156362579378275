import { Button, Card } from "@material-ui/core";
import { useEffect, useState } from "react";
import { getDateTime, getInstallationFieldObj } from "../../../../utils/helper";
import { DataGrid } from "@mui/x-data-grid";

import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import CustomNoRowsOverlay from "../../../../components/common/CustomNoRowsOverlay";
import CustomToolbar from "../../../../components/common/CustomToolbar";
const useStyles = makeStyles({
  root: {
    "& .scheme-data-table": {
      fontWeight: "bold",
    },
  },
});

const InstalledWardList = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    let tableDataTemp = [];
    if (props.schemes && props.schemes.length) {
      props.schemes.map((page, i) => {
        if (props.showWardsIds && props.showWardsIds.length) {
          if (!props.showWardsIds.includes(page["id"])) {
            return;
          }
        }

        let dateTime = { time: "-", date: "-" };
        let network = false;
        dateTime = getDateTime(page["_ts"]);

        let tableObj = {
          ...page,
          ...page.other,
          nicId: page.nicdata ? page.nicdata.NICDeviceId : "",
          did: page.other.deviceid,
          wname: page.name,
          assignedOn: page.installation_user_mapping
            ? page.installation_user_mapping.date
            : "",
          assignedTo: page.installation_user_mapping
            ? page.installation_user_mapping.name
            : "",
          completed_status: page,
          completed_date: page.verification ? page.verification.date : "-",
          datastamp: dateTime,
          action: page,
          network,
          id: page.id,
          slno: i + 1,
          remark:
            page.installation_data && page.installation_data.remark
              ? page.installation_data.remark
              : "-",
          // page.verification && page.verification.status
          //   ? "-"
          //   : "Approval Pending",
        };

        tableDataTemp.push(tableObj);
      });
    }

    setTableData(tableDataTemp);
  }, [props.allSchemes]);

  const handleClick = (data) => {
    navigate("device-info", {
      state: {
        wardData: data,
        assignmentType: "New Device Installation",
      },
    });
  };

  const getColor = (v) => {
    if (v.includes("Pending")) return "blue";
    else if (v.includes("Failed")) return "red";
    else if (v.includes("Approved")) {
      return "green";
    } else {
      return "black";
    }
  };

  const columns = [
    {
      field: "slno",
      headerName: "Sl No",
      width: 50,
      sortable: false,
      headerClassName: "scheme-data-table",
    },
    {
      field: "id",
      hide: true,
      width: 100,
      sortable: false,
      headerClassName: "scheme-data-table",
    },
    {
      field: "districtName",
      headerName: "District",
      width: 120,
      sortable: false,
    },
    {
      field: "division",
      headerName: "Division",
      width: 130,
      sortable: false,
    },
    {
      field: "blockName",
      headerName: "Block",
      width: 130,
      sortable: false,
    },
    {
      field: "panchayatName",
      headerName: "Panchayat",
      width: 130,
      sortable: false,
    },
    {
      field: "wname",
      headerName: "Ward",
      width: 50,
      sortable: false,
    },
    {
      field: "subScheme",
      headerName: "Scheme Type",
      width: 80,
      sortable: false,
    },
    {
      field: "assignedTo",
      headerName: "Assigned To",
      width: 80,
    },
    {
      field: "assignedOn",
      headerName: "Assigned On",
      width: 130,
      sortable: false,
    },
    {
      field: "completed_status",
      headerName: "Installation Status",
      width: 120,
      sortable: false,
      disableExport: true,
      renderCell: (params) => {
        const obj = getInstallationFieldObj(params["formattedValue"]);
        return (
          <span
            style={{
              color: obj["color"],
              backgroundColor: obj["bgColor"] ? obj["bgColor"] : "",
            }}
          >
            {obj?.text !== "Completed" && obj?.text !== "Rejected"
              ? "Pending"
              : obj?.text}
          </span>
        );
      },
    },
    {
      field: "completed_date",
      headerName: "Completed On",
      width: 120,
      sortable: false,
    },
    {
      field: "remark",
      headerName: "Remark",
      width: 130,
      sortable: false,
      renderCell: (params) => {
        return (
          <span style={{ color: getColor(params["formattedValue"]) }}>
            {params["formattedValue"]}
          </span>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 130,
      sortable: false,
      disableExport: true,
      renderCell: (params) => {
        return (
          <Button onClick={() => handleClick(params["formattedValue"])}>
            View Details
          </Button>
        );
      },
    },
  ];

  return (
    <Card>
      <div style={{ height: 800, width: "100%" }} className={classes.root}>
        <DataGrid
          components={{
            Toolbar: CustomToolbar,
          }}
          loading={!tableData.length}
          checkboxSelection
          disableSelectionOnClick
          onSelectionModelChange={(ids) => {
            const selectedIDs = new Set(ids);
            const selectedRowData = tableData.filter((row) =>
              selectedIDs.has(row.id.toString())
            );
            props.onSelect(selectedRowData);
          }}
          // loading={true}
          rows={tableData}
          columns={columns}
          pageSize={40}
          rowsPerPageOptions={[5]}
          slots={{
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          localeText={{ noRowsLabel: "No Records  Found :)" }}
        />
      </div>

      {/* {lastPowerOn["status"] == "loading" ? <Loader /> : null} */}
    </Card>
  );
};

export default InstalledWardList;
