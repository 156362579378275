import { createSlice } from '@reduxjs/toolkit';
import { API } from '../config';
import { get } from './service';

const initialState = {
    scheduled: {
        status: null,
        data: null
    }
};

const slice = createSlice({
    name: 'scheduledData',
    initialState,
    reducers: {
        setScheduledData(state, action) {
            state.scheduled = action.payload
        }
    }
});

export const { reducer } = slice;

export const getScheduledData = (did, dateRange) => async (dispatch) => {
    dispatch(slice.actions.setScheduledData({ ...initialState.scheduled, status: "loading" }));

    get(API.base_url + "phed/shceduled_slot_data", { id: did, from: dateRange[0], to: dateRange[1] })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setScheduledData(response));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setScheduledData({ ...errResponse, status: "failed" }))
        });
};

export default slice;