import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/styles";
import dayjs from "dayjs";
import CustomNoRowsOverlay from "../../../../components/common/CustomNoRowsOverlay";
import CustomToolbar from "../../../../components/common/CustomToolbar";

const useStyles = makeStyles({
    root: {
        "& .scheme-data-table": {
            fontWeight: "bold",
        },
    },
});

const AvarageList = ({ data, title = ' ', fileName = null, locationName = null, height = 500, width = "100%", disableExport = true }) => {
    const classes = useStyles();
    const [tableData, setTableData] = useState([]);
    console.log("data", data)
    useEffect(() => {
        const tableDataTemp = data
            .map((sc, i) => {
                return {
                    id: i + 1,
                    ...sc
                };
            }) || [];

        setTableData(tableDataTemp);
    }, [data]);

    const columns = [
        {
            field: "duration",
            headerName: "Duration",
            width: 200,
            sortable: false,
        },
        {
            field: "electricity",
            headerName: "Electricity (Hrs)",
            width: 200,
            sortable: false,
        },
        {
            field: "motor",
            headerName: "Motor (Hrs)",
            width: 200,
            sortable: false,
        },
        {
            field: "water",
            headerName: "Water Volume (KL)",
            width: 200,
            sortable: false,
        },
    ];

    return (
        <div style={{ height, width }} className={classes.root}>
            <DataGrid
                disableSelectionOnClick
                rows={tableData}
                columns={columns}
                pageSize={40}
                rowsPerPageOptions={[5]}
                components={{
                    Toolbar: CustomToolbar,
                }}
                componentsProps={{
                    toolbar: {
                        title: title,
                        fileName: fileName,
                        locationName: locationName,
                        disableExport: disableExport,
                    },
                }}
                slots={{
                    noRowsOverlay: CustomNoRowsOverlay,
                }}
                localeText={{ noRowsLabel: "No Records Found :)" }}
            />
        </div>
    );
};

export default AvarageList;
